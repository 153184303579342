import { getLanguage } from "./v2/i18n/Translations";

const pageTypes = {
  "/": "Home",
  "/en/": "Home",
  "/vyhody": "Benefits",
  "/en/benefits": "Benefits",
  "/postup": "Process",
  "/en/how-to-start": "Process",
  "/kontakt": "contact",
  "/en/contact": "contact",
};

export const virtualVersion = () => {
  window.dataLayer.push({
    event: "virtualPageview",
    newVersion: "true",
    virtualPageURL: window.location.pathname,
    virtualPageTitle: window.document.title,
    pageType: pageTypes[window.location.pathname],
    languageVersion: getLanguage(),
    softwareVersion: "1.0",
    environment: "DEV",
    server: "EXPRESSJS",
  });
};

export const formFieldClick = (name) => {
  window.dataLayer.push({
    event: "formClick",
    formName: "contactForm",
    fieldName: name,
  });
};

export const succesedFromSubmit = () => {
  window.dataLayer.push({
    event: "formSubmitted",
    formName: "contactForm",
  });
};

export const failedFromSubmit = (errorMessage) => {
  window.dataLayer.push({
    event: "formFailed",
    formName: "contactForm",
    errorMessage: errorMessage,
  });
};

export const formFiledFilled = (fieldName) => {
  window.dataLayer.push({
    event: "formFiledFilled",
    formName: "contactForm",
    fieldName: fieldName,
  });
};

export const formFieldError = (fieldName, errorMessage) => {
  window.dataLayer.push({
    event: "formError",
    formName: "contactForm",
    fieldName: fieldName,
    errorMessage: errorMessage,
  });
};

export const changeLanguageDataLayer = (language) => {
  window.dataLayer.push({
    event: "changeLanguage",
    language: language,
  });
};

export const showcase = (showcaseAction) => {
  window.dataLayer.push({
    event: "showcase",
    showcaseAction: showcaseAction,
  });
};

export const popupShown = (popupType) => {
  window.dataLayer.push({
    event: "popup",
    popupType: popupType,
  });
};

export const menuClick = (button) => {
  window.dataLayer.push({
    event: "clickMenu",
    button: button,
  });
};

export const checkboxDataLayer = (checkboxType) => {
  window.dataLayer.push({
    event: "clickCheckbox",
    checkboxType: checkboxType,
  });
};

export const buttonClickDataLayer = (location, text) => {
  window.dataLayer.push({
    event: "clickButton",
    button: text,
    buttonLocation: location,
  });
};

export const pdfOpen = () => {
  window.dataLayer.push({
    event: "openPDF",
  });
};
